// Vue.use(VueRouter);
import { createWebHistory, createRouter } from "vue-router";

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("./views/Home.vue"),
	},
	{
		path: "/onboarding",
		name: "video",
		component: () => import("./views/Video.vue"),
	},
	{
		path: "/:linkToken",
		name: "fetch-link",
		component: () => import("./views/Fetch_Multiple.vue"),
	},
	{
		path: "/v2/:linkToken",
		name: "fetch-link-v2",
		component: () => import("./views/Fetch_V2.vue"),
	},
	{
		path: "/v3/:linkToken",
		name: "fetch-link-multiple",
		component: () => import("./views/Fetch_Multiple.vue"),
	},
	{
		path: "/create",
		name: "create",
		component: () => import("./views/Create_V2.vue"),
	},
	{
		path: "/create-v2",
		name: "create-v2",
		component: () => import("./views/Create_V2.vue"),
	},
	{
		path: "/sharing/:linkToken",
		name: "sharing",
		component: () => import("./views/Sharing.vue"),
	},
	{
		path: "/welcome",
		name: "welcome",
		component: () => import("./views/DesktopWelcome.vue"),
		props: route => ({ originUrl: route.query.origin })
	},
	{
		path: "/referral",
		name: "referral",
		component: () => import("./views/Referral.vue"),
	},
	{
		path: "/links",
		name: "links",
		component: () => import("./views/Links.vue"),
	},
	{
		path: "/wallet",
		name: "wallet",
		component: () => import("./views/Wallet.vue"),
	},
	{
		path: "/account-update",
		name: "account-update",
		component: () => import("./views/AccountUpdate.vue"),
	},
	{
		path: "/account",
		name: "account",
		component: () => import("./views/MyAccount.vue"),
	},
	{
		path: "/stats",
		name: "stats",
		component: () => import("./views/Stats.vue"),
	},
	{
		path: "/account-update-us",
		name: "account-update-us",
		component: () => import("./views/AccountUpdateUS.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("./views/Login.vue"),
	},
	{
		path: "/auth",
		name: "auth",
		component: () => import("./views/Auth.vue"),
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("./views/Terms.vue"),
	},
	{
		path: "/tcs",
		name: "tcs",
		component: () => import("./views/TCs.vue"),
	},
	{
		path: "/verify-account",
		name: "verify-account",
		component: () => import("./views/Verify.vue"),
	},
	{
		path: "/help",
		name: "help",
		component: () => import("./views/Support.vue"),
	},
	{
		path: "/ambassador",
		name: "ambassador",
		component: () => import("./views/Ambassador.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
